<template lang="pug">
div.container
  b-row(align-h="center").mb-4
    h1 Create New Member

  div.row
    div.col-md-4.d-none.d-md-block
      b-list-group.p-2
        b-list-group-item(active) Membership
        b-list-group-item.d-flex.justify-content-between.align-items-center Type <span>{{newMember.membershipType}}</span>
        b-list-group-item.d-flex.justify-content-between.align-items-center Joined <span>{{newMember.createdDate | moment("MMMM YYYY")}}</span>
        b-list-group-item.d-flex.justify-content-between.align-items-center(v-if="newMember.membershipType != 'Lifetime'") Expires <span>{{newMember.validUntil | moment("MMMM YYYY")}}</span>
      b-list-group.p-2
        b-list-group-item(active) Contact Information
        b-list-group-item.d-flex.justify-content-between.align-items-center Email <span>{{newMember.email}}</span>
        b-list-group-item.d-flex.justify-content-between.align-items-center Residence <span>{{newMember.postOffice}}</span>
    div.col-md-8
      b-form
        b-form-row
          b-form-group.col-sm-4(label="Membership Type *" label-for="type")
            b-select(name="type" v-model="newMember.membershipType" required)
              option Individual
              option Unemployed
              option Student
              option Lifetime
        b-form-row
          b-form-group.col-sm(label="First Name *" label-for="firstName")
            b-input(type="text" name="firstName" v-model="newMember.firstName" required)
          b-form-group.col-sm(label="Last Name *" label-for="lastName")
            b-input(type="text" name="lastName" v-model="newMember.lastName" required)
        b-form-row
          b-form-group.col-sm(label="Email *" label-for="email")
            b-input(type="email" name="email" v-model="newMember.email" required)
          b-form-group.col-sm(label="Place of Residence *" label-for="city")
            b-input(type="text" name="city" v-model="newMember.postOffice")
        b-form-row.justify-content-center
          b-button.m-4(variant="success" @click="createMember(newMember)" :disabled="!isFormValid") Create Member
</template>

<script>
export default {
  data: function () {
    return {
      newMember: {
        membershipType: 'Individual',
        createdDate: new Date(),
        validUntil: new Date(), // For preview only - will be recomputed in backend
        firstName: '',
        lastName: '',
        email: '',
        postOffice: '',
        interests: {}
      }
    }
  },
  computed: {
    isFormValid: function () {
      let result = true
      if (this.newMember.firstName === '' ||
        this.newMember.lastName === '' ||
        this.newMember.email === '' ||
        this.newMember.postOffice === '') result = false
      return result
    }
  },
  mounted: function () {
    this.newMember.validUntil.setFullYear(this.newMember.validUntil.getFullYear() + 1, this.newMember.validUntil.getMonth() + 1, 0)
    this.newMember.validUntil.setHours(23, 59, 59)
  },
  methods: {
    createMember: function (newMember) {
      this.axios.post('/api/individual-members/new', newMember)
        .then(res => {
          this.$notify({
            group: 'success',
            type: 'success',
            title: 'Member created',
            text: res.data.firstName + ' is now in the club!'
          })

          this.$router.push({ path: '/individual-members' })
        })
        .catch(err => {
          this.$notify({
            group: 'error',
            type: 'error',
            title: 'Creating member failed',
            text: err.response.data,
            duration: -1
          })
        })
    }
  }
}
</script>
